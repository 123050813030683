import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";

const details = {
  title: "Pravara Medical Trust, Loni, Maharashtra",
  address: "Loni, Maharashtra",
  client: "Pravara Medical Trust",
  area: "15,000 Sq. Ft.",
  project:
    "Corporate Office for Pravara Institute of Medical Sciences, Loni, Maharashtra",
  category: "Residential | Architecture",
  status: "Under Construction",
  backlink: "/projects/residential/",
};

export default class Projectpravaramedicaltrustlonimaharashtra extends Component {
  render() {
    return (
      <Layout>
        <Header isWhite />
        <ProjectLayout
          details={details}
          projectimages={sliderImages}
          img="https://archdesignsstudio.com/assets/linkpreview.png"
        />
      </Layout>
    );
  }
}
