import React from "react";

import facebookicon from "../images/AND_SM_icon-01.jpg";
import twittericon from "../images/AND_SM_icon-02.jpg";
import instaicon from "../images/AND_SM_icon-03.jpg";
import linkedinicon from "../images/AND_SM_icon-04.jpg";

export default function StickySocial() {
  return (
    <div className="stickysocial">
      <div className="stickysocial__button">
        <img src={facebookicon} alt="A&D facebook Icon"></img>
      </div>
      <div className="stickysocial__button">
        <img src={twittericon} alt="A&D Twitter Icon"></img>
      </div>
      {/* <div className="stickysocial__button">
        <img src={instaicon} alt="A&D Instagram Icon"></img>
      </div> */}
      <div className="stickysocial__button">
        <a
          href="https://www.linkedin.com/company/architecture-designs-studio/"
          target="__blank"
        >
          <img src={linkedinicon} alt="A&D Linkedin Icon"></img>
        </a>
      </div>
    </div>
  );
}
