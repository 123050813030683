import React from "react";
import "./footer.component.css";
import BELogo from "../images/BE_logo_100X100.png";
function Footer() {
  return (
    <footer className="footer">
      <div style={{ margin: "5px 0" }}>
        © A&D Studio {new Date().getFullYear()}. All Rights Reserved
        {/* <span style={{ marginLeft: "3px" }}>
          <a>Privacy Policy</a>
        </span> */}
      </div>

      <div style={{ display: "flex", lineHeight: "24px" }}>
        <span style={{ marginRight: "10px", marginTop: "3px" }}>
          Powered By
        </span>
        <a href="https://blackelephantdigital.com">
          <img alt="Black Elephant Digital logo" src={BELogo}></img>
        </a>
      </div>
    </footer>
  );
}
export default Footer;
